<template>
  <div class="new fullscreen ui block">
    <c-header subtitle="Nouveau" button></c-header>

    <template v-if="loader">
      <div class="content text-center flex-container">
        <div class="flex-item-center">
          <beat-loader :color="this.$colors.blue"></beat-loader>
        </div>
      </div>
    </template>

    <template v-if="!loader">
      <div class="content no-padding">
        <ul class="tab tab-fixed row text-center">
          <li class="col-4" v-on:click="(!finish ? state=1 : null)"
              v-bind:class="{'click':state>1 && !finish, 'no-click':(state<=1 || finish), 'active':state===1}">
            1
          </li>
          <li class="col-4" v-on:click="(!finish && hasChoices() ? state=2: null)"
              v-bind:class="{'click':(!finish && hasChoices()), 'no-click':!(!finish && hasChoices()) || finish, 'active':state===2}">
            2
          </li>
          <li class="col-4"
              v-bind:class="{'no-click':(state<=3 || finish), 'active':state===3}">3
          </li>
        </ul>
        <div class="pane">

          <div class="pane-1 animated fadeIn" v-if="state===1">
            <div :key="key" v-for="(item,key) in docs">
              <div v-if="Object.keys(item.documentations).length >=1">
                <div class="title">
                  <div class="title-icon"><i v-bind:class="item.icon"></i></div>
                  <div class="title-name"><span v-resize-text="{maxFontSize: '23px'}">{{ item.name }}</span></div>
                </div>
                <div class="clear"></div>
                <ul class="items groupedby margin">
                  <li class="item click" :key="key" v-for="(doc,key) in item.documentations"
                      v-on:click="toggleChoiceDoc(doc.id)">
                    <div class="item-radio-button" v-bind:class="{'active': isChoice(doc.id)}"></div>
                    <div class="item-name">{{ doc.name }}</div>
                    <div class="clear"></div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="text-center">
              <img v-on:click="(hasChoices() ? state=2 : null)" v-bind:class="{'opacity':!hasChoices()}"
                   src="../assets/img/ui-button-blue-right.svg" alt="">
            </div>
          </div>


          <div class="pane-2 animated fadeInRight" v-if="state===2">
            <form method="POST">
              <h4>Obligatoire</h4>
              <div class="text-center">
                <div class="input icon">
                  <input type="email" name="email" v-on:change="checkEmailDatabase()"
                         v-model="form.email" placeholder="E-mail" v-validate="'required|email'">
                  <img src="../assets/img/icon-at.svg" alt="@">
                </div>
                <p class="text-danger text-light"
                   style="font-size: 12px; font-size: 12px; margin: 13px 0; line-height: 14px"
                   v-if="errors.has('email')">{{ errors.first('email') }}</p>

                <div class="input buttons-group"
                     v-bind:class="{'opacity':(typeof form.email == 'undefined' || form.email == null || this.form.email.length <= 0)}">
                  <template
                      v-if="display_domains">
                    <div class="button mini click" v-on:click="choice_domain(item)"
                         :key="key"
                         v-for="(item, key) in Object.keys(mail_domains).slice(0, 50)">@{{ item }}
                    </div>
                  </template>
                </div>
              </div>

              <div v-show="form.email != null && form.email.length >=1 && !errors.has('email')">
                <div class="text-center">
                  <img src="../assets/img/ui-button-blue-plus.svg" class="space" alt="">
                </div>

                <h4>Facultatif</h4>
                <div class="text-center">
                  <div class="input icon">
                    <input name="name" type="text" v-model="form.name" placeholder="Nom">
                    <img src="../assets/img/icon-user.svg" alt="Avatar">
                  </div>
                  <div class="input icon">
                    <input name="firstname" type="text" v-model="form.firstname" placeholder="Prénom">
                    <img src="../assets/img/icon-user.svg">
                  </div>
                  <div class="input icon">
                    <input name="organisation" type="text" v-model="form.organization"
                           placeholder="Établissement, Organisme, Societé">
                    <img src="../assets/img/icon-university.svg">
                  </div>
                  <div class="input icon" v-if="form.status !== '-'">
                    <select name="function" v-model="form.status">
                      <option value="0" disabled selected>Fonction</option>
                      <option :key="st.id" v-bind:value="st.name" v-for="st in status_available">
                        {{ st.name }}
                      </option>
                      <option value="-">Autre</option>
                    </select>
                    <img height="17px" src="../assets/img/icon-briefcase.svg">
                    <img src="../assets/img/ui-select-blue-button.svg" class="select">
                  </div>
                  <div class="input icon" v-else>
                    <input name="name" type="text" v-model="form.status_specific"
                           placeholder="Préciser la fonction ...">
                    <img src="../assets/img/icon-briefcase.svg">
                  </div>
                  <div class="input icon">
                    <input name="phone" type="text" v-model="form.phone" placeholder="Téléphone">
                    <img src="../assets/img/icon-phone.svg">
                  </div>
                  <div class="input">
                    <textarea placeholder="Projets" v-model="form.project" rows="10"></textarea>
                  </div>
                </div>
                <div class="text-center">
                  <div class="input button">
                    <button type="submit" @click="send_documentation">
                      <img src="../assets/img/ui-button-blue-validate.svg" alt="Valider">
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="pane-3 flex-container" v-if="state===3">
            <div class="view-recap flex-item-center animated fadeInUp">
              <img src="../assets/img/tunnel-end.svg" alt="">
              <h4>Envoyer</h4>
              <p>Les documentations sélectionnées vont être envoyées par mail.</p>
            </div>
          </div>
          <template v-if="!loader && state==='success'">
            <div class="content text-center flex-container">
              <div class="flex-item-center">
                <div class="element view-recap animated fadeInUp">
                  <img src="../assets/img/tunnel-end.svg" alt="">
                  <p class="text-light mt-3">Un mail a été envoyé à votre correspondant avec la documentation
                                             choisie.</p>
                </div>
              </div>
            </div>
          </template>
          <template v-if="!loader && state==='success_later'">
            <div class="content text-center flex-container" v-if="!loader && state==='success_later'">
              <div class="flex-item-center">
                <div class="element view-recap animated fadeInUp">
                  <img src="../assets/img/tunnel-end-later.svg" alt="">
                  <p class="text-light mt-3">Un mail accompagné de la documentation va être envoyé à votre correspondant
                                             lorsque
                                             vous serez connecté à internet.</p>
                </div>
              </div>
            </div>

          </template>

          <template v-if="!loader && state==='error'">
            <div class="content text-center flex-container" v-if="!loader && state==='error'">
              <div class="flex-item-center">
                <div class="element view-recap animated fadeInUp">
                  <img src="../assets/img/tunnel-end-fail.svg" width="168px" alt="">
                  <p class="text-light">Impossible d'envoyer le mail à votre correspondant, merci de renouveler
                                        l'opération ultérieurement.</p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Router, {ROUTE_NAME} from "../plugins/router";

import CHeader from "../components/Header";
import BeatLoader from 'vue-spinner/src/BeatLoader'
import {mapGetters} from "vuex";
import {STATUS} from "@/store/info";

export default {
  name: 'NewView',
  data() {
    return {
      // UI
      loader: true,
      state: 1,
      display_domains: true,

      // DATA
      docs: null,
      docs_choice: {},
      finish: false,
      form: {
        email: null,
        name: null,
        firstname: null,
        organization: null,
        status: '0',
        status_specific: null,
        phone: null,
        project: null
      },
      mail_domains_generic: {
        "gmail.com": 10,
        "outlook.fr": 10,
        "hotmail.fr": 10,
        "live.fr": 10,
        "laposte.net": 10,
        "yahoo.fr": 10,
        "wanadoo.fr": 10,
        "orange.fr": 10,
        "gmx.fr": 10,
        "sfr.fr": 10,
        "neuf.fr": 10,
        "free.fr": 10,
        "icloud.com": 10,
        "me.com": 10,
      },
      mail_domains_prospects_yet: {}
    }
  },
  computed: {
    ...mapGetters({
      'status_available': 'ProspectTypes/getProspectTypeList',
      'event': 'Events/getSelectedEventId'
    }),
    mail_domains: function () {
      let domains = Object.assign(this.mail_domains_generic, this.mail_domains_prospects_yet);

      if (typeof this.form.email != "undefined" && this.form.email != null && this.form.email.indexOf('@') > -1) {
        let tmp = this.form.email.split("@");
        let domains_filtered = {};

        for (var key in domains) {
          if (typeof domains[key] !== 'function') {
            var value = domains[key];
            let domain = "@" + key;
            if (domain.indexOf("@" + tmp[1]) > -1) domains_filtered[key] = value;
          }
        }

        if (Object.keys(domains_filtered).length === 1 && Object.keys(domains_filtered) === tmp[1]) {
          domains_filtered = {}
        }
        return domains_filtered;
      } else {
        return domains;
      }
    }
  },
  methods: {
    /**
     * @param {Number} id
     * @returns {Boolean}
     */
    isChoice: function (id) {
      return (this.docs_choice[id] !== undefined && this.docs_choice[id])
    },
    /**
     * @returns {boolean}
     */
    hasChoices: function () {
      return Object.keys(this.docs_choice).length >= 1;
    },
    generateAvailableDomains: function () {
      this.$store.getters['Prospects/getAllProspects'].forEach(prospect => {
        let domain = prospect.email.split('@');
        domain = domain[1];

        if (domain.length >= 1) {
          if (this.mail_domains_prospects_yet[domain] !== undefined)
            this.mail_domains_prospects_yet[domain]++;
          else {
            this.mail_domains_prospects_yet[domain] = 1;
          }
        }
      })
    },
    /**
     * @param {Number} id
     */
    toggleChoiceDoc: function (id) {
      this.docs_choice[id] = (this.docs_choice[id] === undefined ? true : !this.docs_choice[id]);
      if (this.docs_choice[id] === false) delete this.docs_choice[id];
      this.$forceUpdate();
    },
    /**
     * @param {String} domain
     */
    choice_domain: function (domain) {
      if (this.form.email === undefined
          || this.form.email === null
          || this.form.email.length < 1)
        return

      let tmp = this.form.email.split("@");
      this.form.email = tmp[0] + "@" + domain;
    },
    checkEmailDatabase: function () {
      if (this.form.email === undefined || this.form.email === null)
        return;

      let prospect = this.$store.getters['Prospects/findProspectByEmail'](this.form.email);
      if (prospect === null)
        return;

      try {
        let statusSearch = this.status_available.filter(status => {
          return status.name.trim().toLowerCase() === prospect.status.trim().toLowerCase()
        })

        if (statusSearch.length >= 1) {
          this.form.status = statusSearch[0].name
          this.form.status_specific = null
        } else {
          this.form.status = '-';
          this.form.status_specific = prospect.status;
        }
      } catch (e) {
        this.form.status = '0';
        this.form.status_specific = null;
      }

      this.form.prospect_id = prospect.id
      this.form.email = prospect.email;
      this.form.name = prospect.name;
      this.form.firstname = prospect.firstname;
      this.form.organization = prospect.organisation;
      this.form.phone = prospect.phone;
    },
    resetForm: function () {
      this.form.prospect_id = null;
      this.form.event_id = null;
      this.form.docs = null;

      this.form.email = null;
      this.form.name = null;
      this.form.firstname = null;
      this.form.organization = null;
      this.form.status = '0';
      this.form.status_specific = null;
      this.form.phone = null;
      this.form.project = null;
      this.docs_choice = {}
    },
    send_documentation: function () {
      this.loader = true;

      this.$validator.validate().then(async valid => {
        if (!valid) {
          this.loader = false;
          return;
        }

        try {
          this.form.event_id = this.event
          this.form.docs = Object.keys(this.docs_choice)
          let state = await this.$store.dispatch('Info/sendInformationRequest', JSON.parse(JSON.stringify(this.form)));
          this.state = (state === STATUS.LATER ? 'success_later' : 'success')

          if (state === STATUS.SENT)
            this.syncProspect(true).then()

          this.loader = false;
          this.finish = true
          this.resetForm()
        } catch (e) {
          this.state = "error";
          this.loader = false;
        }
      });
    },
    /**
     * @param {Boolean} force
     * @returns {Promise<void>}
     */
    syncProspect: async function (force = false) {
      let events_id = this.$store.getters['Events/getEventListIds']();

      if (force || !this.$store.getters['Prospects/hasValidProspects'])
        await this.$store.dispatch("Prospects/pullProspectsForAllEvents", events_id, {root: true})
    },
  },
  components: {
    "c-header": CHeader,
    BeatLoader
  },
  watch: {
    'form.email': function (val) {
      this.display_domains = true
      if (val === undefined || val === null || val.length === 0)
        return

      this.form.email = this._.toLower(val.trim()).replace(/ /g, "-");

      let sp = (this.form.email.split("@"))
      if (sp[1] !== undefined
          && (this.mail_domains_generic[sp[1]] !== undefined
              || this.mail_domains_prospects_yet[sp[1]] !== undefined)
      ) {
        this.display_domains = false
      }
      this.$forceUpdate();
    }
  },
  mounted: function () {
    this.resetForm()

    if (!this.$store.getters['Docs/hasDocumentsForEvent'](this.event) && !this.$store.getters['Prospects/hasProspects']() && !this.$store.getters['ProspectTypes/hasProspectTypeList']()) {
      Router.push({name: ROUTE_NAME.sync});
      return;
    }

    this.docs = (this.event === null ? null : this.$store.getters['Docs/getDocumentsForEvent'](this.event));
    this.generateAvailableDomains();
    this.loader = false;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.opacity {
  opacity: 0.5;
}

.pane-1 {
  margin-bottom: 30px;
}
</style>
