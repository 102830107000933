import axios from "axios";
import moment from "moment";

export const STATUS = {
	LATER: 'later',
	SENT: 'sent'
}

const state = {
	card_request: {}
};

const getters = {
	/**
	 * @param state
	 * @returns {Object}
	 */
	getSendCardRequests: state => (state.card_request),
	/**
	 * @param state
	 * @returns {function(): boolean}
	 */
	hasSendCardRequest: state => ()=>{
		return (state.card_request === undefined ? false : Object.keys(state.card_request).length >= 1)
	}
};

const mutations = {
	/**
	 * @param state
	 * @param {{email : String, message: String}} data
	 */
	addSendCardRequest: (state, data) => {
		state.card_request[data.email.trim()] = {
			email: data.email.trim(),
			message: data.message,
			set_at: moment()
		}
	},
	/**
	 * @param state
	 * @param {String} email
	 * @returns {boolean}
	 */
	removeSendCardRequest: (state, email) => {
		delete state.card_request[email];
		return true;
	},
	/**
	 * @param state
	 * @param {Object} data
	 */
	setSendCardRequestList: (state, data = {}) => {
		state.card_request = data;
	}
};

const actions = {
	/**
	 * @param context
	 * @param {{email : String, message: String}} card
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	sendCardAction: (context, card) => {
		return axios({
			method: 'post',
			url: '/user/card/send',
			auth: {
				username: context.rootState.Auth.username,
				password: context.rootState.Auth.password
			},
			data: {
				email: card.email,
				message: card.message
			}
		})
	},
	/**
	 *
	 * @param context
	 * @param {{email : String, message: String}} card
	 * @returns {Promise<unknown>}
	 */
	sendCardRequest: async (context, card) => {
		if (!window.navigator.onLine) {
			context.commit('addSendCardRequest', card);
			return STATUS.LATER
		}

		try {
			await context.dispatch("sendCardAction", card)
			return STATUS.SENT
		} catch (err) {
			context.commit('addSendCardRequest', card);
			return STATUS.LATER
		}
	},
	/**
	 * @param context
	 * @returns {Promise<boolean>} True if all card requests are be sent
	 */
	syncCardRequest: async (context) => {
		if(!context.getters.hasSendCardRequest()) return

		for (const cardSendRequest of Object.values(context.getters.getSendCardRequests)) {
			try {
				await context.dispatch("sendCardAction", cardSendRequest)
				context.commit("removeSendCardRequest", cardSendRequest.email);
			} catch (err) {
				// nothing to catch
			}
		}

		return !context.getters.hasSendCardRequest()
	},
	/**
	 * @param context
	 */
	clearSendCardRequestList: (context) => {
		context.commit('setSendCardRequestList', {});
	}
};

export default {namespaced: true, state, getters, mutations, actions};
