<template>
  <div class="carte-de-visite fullscreen ui block">
    <c-header subtitle="Ma carte de visite" button></c-header>

    <template v-if="loader">
      <div class="content text-center flex-container">
        <div class="flex-item-center">
          <beat-loader :color="this.$colors.blue"></beat-loader>
        </div>
      </div>
    </template>

    <template v-if="!loader && state==='tape'">
      <div class="content">
        <p class="text-light">Merci de saisir l’adresse mail du destinataire ainsi qu’un message qui accompagnera votre
                              carte de visite.</p>

        <div class="form" v-bind:class="form._effect" @submit="form_send">
          <div class="input icon" :class="{'has-error': errors.has('email') }">
            <input type="email" name="email" ref="email" placeholder="Adresse e-mail" v-model="form.email"
                   v-validate="'required|email'" autofocus />
            <img src="../assets/img/icon-at.svg" alt="@">
          </div>
          <p class="text-danger" style="font-size: 12px; margin: 5px;" v-if="errors.has('email')">
            {{ errors.first('email') }}</p>

          <div class="input icon">
            <input type="text" name="name" ref="name" placeholder="Prénom Nom" v-model="form.name" autofocus />
            <img src="../assets/img/icon-user.svg" alt="">
          </div>

          <div class="input">
            <textarea placeholder="Message" id="card-message" name="message" rows="12" v-model="form.message"
                      v-validate="'required'"></textarea>
          </div>
          <p class="text-danger" style="font-size: 12px; margin: 5px;" v-if="errors.has('message')">
            {{ errors.first('message') }}
          </p>
        </div>

        <div class="text-center flex-container">
          <img src="../assets/img/ui-button-blue-validate.svg" v-on:click.prevent="form_send" alt="Validate">

          <router-link :to="{name:'Card'}">
            <img src="../assets/img/ui-button-blue-close.svg" style="margin-left: 6px; width: 30px" alt="Close">
          </router-link>
        </div>
      </div>
    </template>

    <template v-if="!loader && state==='success'">
      <div class="content text-center flex-container">
        <div class="flex-item-center">
          <div class="element view-recap animated fadeInUp">
            <img src="../assets/img/tunnel-end.svg" alt="">
            <p class="text-light">Un mail a été envoyé à votre correspondant accompagné du message saisi.</p>
          </div>
        </div>
      </div>

      <c-footer color="" classes="text-center">
        <router-link :to="{name:'Card'}">Ma carte de visite</router-link>
      </c-footer>
    </template>

    <template v-if="!loader && state==='success_later'">
      <div class="content text-center flex-container" v-if="!loader && state==='success_later'">
        <div class="flex-item-center">
          <div class="element view-recap animated fadeInUp">
            <img src="../assets/img/tunnel-end-later.svg" alt="">
            <p class="text-light">
              Un mail accompagné du message saisi va être envoyé à votre correspondant.
              Si vous n’êtes pas connecté à internet, il sera transmis lors de la prochaine synchronisation.
            </p>
          </div>
        </div>
      </div>

      <c-footer color="" classes="text-center">
        <router-link :to="{name:'Card'}">Ma carte de visite</router-link>
      </c-footer>
    </template>

    <template v-if="!loader && state==='error'">
      <div class="content text-center flex-container" v-if="!loader && state==='error'">
        <div class="flex-item-center">
          <div class="element view-recap animated fadeInUp">
            <img src="../assets/img/tunnel-end-fail.svg" width="168px" alt="">
            <p class="text-light">
              Impossible d'envoyer le mail à votre correspondant, merci de renouveler l'opération ultérieurement.
            </p>
          </div>
        </div>
      </div>

      <c-footer color="" classes="text-center">
        <span class="text-blue text-14" v-on:click="state='tape'">Réessayer</span>
      </c-footer>
    </template>
  </div>
</template>

<script>
import Router, {ROUTE_NAME} from "../plugins/router";
import moment from "moment"

import BeatLoader from 'vue-spinner/src/BeatLoader'
import CHeader from "../components/Header";
import CFooter from '../components/Footer'

import {STATUS} from "@/store/card";
import {mapGetters} from "vuex";

export default {
  name: 'CardQrCode',
  data() {
    return {
      loader: false,
      state: "tape",
      form: {
        _effect: '',
        email: '',
        name: '',
        message: '',
        change: false
      }
    }
  },
  computed: {
    ...mapGetters({
      'user': 'User/getUserDetails'
    })
  },
  methods: {
    _formError: function () {
      this.form._effect = "animated shake";

      setTimeout(() => {
        this.form._effect = "";
      }, 500)
    },
    form_send: function () {
      this.loader = true;
      this.$validator.validate().then(async valid => {
        if (!valid) {
          this.loader = false;
          this._formError();
        } else {
          let status = await this.$store.dispatch("Card/sendCardRequest", this.form)

          this.state = status === STATUS.LATER ? "success_later" : "success";
          this.loader = false;
        }
      });
    },
    generateMessage: function () {
      return (() => {
        return "Bonjour " + this.form.name + ",\n" +
            "\n" +
            "Nous nous sommes rencontrés le " + moment().format("DD/MM/YYYY") + " dans le cadre d'un événement.\n" +
            "Je vous transmets ma carte de visite en pièce jointe de ce mail.\n" +
            "\n" +
            "N'hésitez pas à me recontacter.\n" +
            "\n" +
            "Cordialement,\n" +
            this._.toUpperWithoutTrim(this.user.name) + " " + this._.startCaseWithoutTrim(this.user.firstname);
      })();
    }
  },
  components: {
    "c-header": CHeader,
    "c-footer": CFooter,
    BeatLoader
  },
  watch: {
    'form.name': function () {
      this.form.message = this.generateMessage()
    },
    'form.message': function (val) {
      this.form.change = this.generateMessage().trim() !== val.trim()
    }
  },
  mounted: function () {
    if (!this.$store.getters['User/hasUserDetails'])
      Router.push({name: ROUTE_NAME.sync});

    this.form.message = this.generateMessage();
    this.$refs.email.focus();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content {
  .element {
    position: relative;
    margin-top: -45px;
  }

  p {
    margin-bottom: 35px;
    color: #808080;
    line-height: 19px;
  }

  .view-recap {
    padding-bottom: 0;
    padding-top: 10px;

    img + p {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
}

footer {
  padding-top: 0;
}

.popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px;
  background: #929da2;
  color: #ffffff;
}
</style>
