<template>
  <div class="menu text-light text-white fullscreen-fixed overflow-y bg-blue ui">
    <template v-if="events != null">
      <div class="content no-salon" v-if="event_in == null">
        <h1>{{ $env.APP_NAME }}</h1>
        <div class="separator"></div>

        <div class="animated fadeInUp">
          <ul class="strong" v-if="hasEvents">
            <li class="click" :key="key" v-for="(event,key) in events" v-on:click="eventChoice(event.id)">{{
                event.name
                                                                                                          }}
            </li>
          </ul>
          <p v-else class="pb-5">Pas d'évenements disponibles</p>

          <div class="separator"></div>
          <ul>
            <li @click="goToSync" class="click">Synchroniser</li>
            <li @click="goToMyCard" class="click">Ma carte de visite</li>
            <li v-on:click="lockout()" class="click">Verrouiller l'application</li>
            <li v-on:click="logout()" class="click">Me déconnecter</li>
          </ul>
        </div>
      </div>


      <div class="content entered-in-salon" v-if="event_in != null && event_details !== null">
        <h1>{{ $env.APP_NAME }}</h1>
        <h2>{{ event_details.name }}</h2>
        <div class="separator"></div>

        <div class="animated fadeInUp">
          <ul>
            <li @click="goToNew" class="click">Nouveau</li>
            <li @click="goToRecent" class="click opacity-50">Récents</li>
            <li @click="goToProspects" class="click opacity-50">Mes prospects</li>
            <li @click="goToDocumentations" class="click opacity-50">Documentations</li>
            <li class="click" v-on:click="goOutOfEvent()">Quitter l’évenement</li>
          </ul>
          <div class="separator"></div>
          <ul>
            <li @click="goToSync" class="click">Synchroniser</li>
            <li @click="goToMyCard" class="click">Ma carte de visite</li>
            <li v-on:click="lockout()" class="click">Verrouiller l'application</li>
            <li v-on:click="logout()" class="click">Me déconnecter</li>
          </ul>
        </div>
      </div>

      <c-footer color="">
        Copyright © {{ $env.APP_COPYRIGHT }} {{ (new Date()).getFullYear() }}
      </c-footer>
    </template>

    <div v-else class="fullscreen d-flex align-items-center justify-content-center">
      <skew-loader :color="this.$colors.white"></skew-loader>
    </div>
  </div>
</template>

<script>
import Router, {ROUTE_NAME} from '../plugins/router'
import SkewLoader from 'vue-spinner/src/SkewLoader'
import CFooter from '../components/Footer'
import {mapGetters} from "vuex";

export default {
  name: 'MenuView',
  data() {
    return {}
  },
  components: {
    SkewLoader,
    'c-footer': CFooter
  },
  computed: {
    ...mapGetters({
      'hasEvents': 'Events/hasEvents',
      'events': 'Events/getEventList',
      'event_in': 'Events/getSelectedEventId',
      'event_details': 'Events/getSelectedEvent'
    })
  },
  methods: {
    goToSync() {
      return this.$router.push({name: ROUTE_NAME.sync, params: {force: true}})
    },
    goToMyCard() {
      return this.$router.push({name: ROUTE_NAME.card})
    },
    goToNew() {
      return this.$router.push({name: ROUTE_NAME.new})
    },
    goToRecent() {
      // return this.$router.push({name: ROUTE_NAME.recent})
    },
    goToProspects() {
      // return this.$router.push({name: ROUTE_NAME.prospects})
    },
    goToDocumentations() {
      // return this.$router.push({name: ROUTE_NAME.documentations})
    },
    eventChoice: function (event_id) {
      this.$store.commit("Events/selectEvent", event_id);
    },
    goOutOfEvent: function () {
      this.$store.commit("Events/selectEvent", null);
    },
    lockout: function () {
      this.$store.dispatch('Auth/lockout', {root: true});
      Router.push({name: ROUTE_NAME.home});
    },
    logout: function () {
      let logout = true;

      if (this.$store.getters["Info/hasInformationRequests"]() || this.$store.getters['Card/hasSendCardRequest']()) {
        logout = window.confirm("Des données sont en attente de synchronisation, vous perdez les données non synchronisées en vous déconnectant.")
      }

      if (!logout)
        return

      this.$store.dispatch('Events/clearEvents', {root: true});
      this.$store.dispatch('Docs/clearDocumentsForAllEvents', {root: true});
      this.$store.dispatch('Prospects/clearAllProspects', {root: true});
      this.$store.dispatch('Card/clearSendCardRequestList');
      this.$store.dispatch('Info/clearInformationRequests')
      this.$store.dispatch('ProspectTypes/clearProspectTypeList')
      this.$store.dispatch('User/clearUser', {root: true});
      this.$store.dispatch('Auth/logout', {root: true});
      Router.push({name: ROUTE_NAME.home});
    },
  },
  mounted: function () {
    if (this.event_in !== null && (this.events === null || this.events.filter(event => event.id).length === 0)) this.goOutOfEvent();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
  margin-top: 8px;
  font-size: 14px;

  & + .separator {
    margin-top: 25px;
  }
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  font-weight: 600;
  margin-left: -3px;
  margin-top: 20px;

  & + .separator {
    margin-top: 25px;
  }
}

.separator {
  border: 1px solid #ffffff;
  width: 63px;
  height: 1px;
  margin-bottom: 30px;
}

ul {
  & + .separator {
    margin-top: 25px;
  }

  > li {
    font-size: 21px;
    line-height: 31px;
    font-weight: 300;
  }

  li.active {
    font-weight: 600;
  }
}

ul.strong {
  margin-bottom: 50px;

  > li {
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 12px;
  }
}
</style>
