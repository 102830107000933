import axios from "axios";
import moment from "moment";

const state = {
	data: null,
	pull_at: null
};

const getters = {
	/**
	 * @param state
	 * @returns {null|Array<Object>}
	 */
	getProspectTypeList: (state) => (state.data),
	/**
	 * @param state
	 * @returns {moment.Moment|null}
	 */
	getLastPullDate: (state) => (state.pull_at || moment(state.pull_at)),
	/**
	 * @param state
	 * @returns {function(): boolean}
	 */
	hasProspectTypeList: (state) => (state.data !== null),
	/**
	 * @param state
	 * @returns {boolean}
	 */
	hasValidProspectTypeList: (state) => (state.data != null && state.pull_at != null)
};

const mutations = {
	/**
	 * @param state
	 * @param {Array<Object>} data
	 */
	setProspectTypeList: (state, data) => {
		state.data = data;
		state.pull_at = moment();
	},
	/**
	 * @param state
	 */
	clearProspectTypeList: (state) => {
		state.data = [];
		state.pull_at = null;
	},
};

const actions = {
	/**
	 * @param context
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	pullProspectTypeList: async (context) => {
		let response = await axios.get('/prospects/types', {
			auth: {
				username: context.rootState.Auth.username,
				password: context.rootState.Auth.password
			}
		})
		context.commit('setProspectTypeList', response.data);

		return response
	},
	/**
	 * @param context
	 */
	clearProspectTypeList: (context) => {
		context.commit('clearProspectTypeList');
	}
};

export default {namespaced: true, state, getters, mutations, actions};
