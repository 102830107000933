<template>
  <div class="password-forget fullscreen ui block">
    <c-header subtitle="Mot de passe oublié"></c-header>

    <div class="content text-center">
      <div :class="{'d-flex fullscreen align-items-center justify-content-center' : loader}">
        <beat-loader :color="this.$colors.blue" v-if="loader"></beat-loader>

        <template v-else>
          <div v-if="state === 'form'">
            <img src="../assets/img/login-user.svg" class="avatar" alt="Avatar">

            <h3>Mot de passe oublié</h3>
            <form v-on:submit.prevent="login" :class="{'animated shake': form.error }"
                  method="POST">
              <div class="input icon" :class="{'has-error': errors.has('email') }">
                <input type="email" name="email" placeholder="Adresse e-mail" v-model="form.email"
                       v-validate="'required|email'" />
                <img src="../assets/img/icon-at.svg" alt="@">
              </div>
              <p class="text-danger" style="font-size: 12px; margin: 5px;" v-if="errors.has('email')">
                {{ errors.first('email') }}</p>

              <div class="input button">
                <button type="submit">
                  <img src="../assets/img/ui-button-blue-validate.svg" alt="Valider">
                </button>
              </div>
            </form>
          </div>

          <div class="view-recap animated fadeInUp" v-if="state === 'success'">
            <img src="../assets/img/tunnel-end.svg" class="avatar" alt="">
            <p class="text-light">
              Votre mot de passe a bien été réinitialiser.<br />
              Votre nouveau mot de passe vous sera envoyé par mail.
            </p>
          </div>
        </template>
      </div>
    </div>

    <c-footer color="" classes="text-center" :active="!loader" style="position: fixed; bottom: 0;">
      <router-link :to="{name:'Login'}">Me connecter</router-link>
    </c-footer>
  </div>
</template>

<script>

import CHeader from '../components/Header'
import CFooter from '../components/Footer'
import BeatLoader from 'vue-spinner/src/BeatLoader'

export default {
  name: 'LoginComponent',
  data() {
    return {
      loader: false,
      state: 'form',
      form: {
        error: false,
        email: ""
      }
    }
  },
  methods: {
    /**
     * Make error animation
     * @private
     */
    _formError: function () {
      this.form.error = true;

      setTimeout(() => {
        this.form.error = false;
      }, 1500);
    },
    login: function () {
      this.loader = true;

      this.$validator.validate().then(async valid => {
        if (!valid) {
          this.loader = false;
          this._formError()
        } else {
          try {
            await this.$store.dispatch('Auth/passwordForget', this.form.email)
            this.state = 'success';
          } catch (err) {
            this._formError()
          } finally {
            this.loader = false;
          }
        }
      });
    }
  },
  components: {
    "c-header": CHeader,
    "c-footer": CFooter,

    BeatLoader
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content {
  img.avatar {
    margin-bottom: 25px;
  }

  h3 {
    margin-bottom: 22px;
  }

  form {
    max-width: 340px;
    margin: 0 auto;
  }

  .element {
    position: relative;
    top: -20px;
  }
}
</style>
