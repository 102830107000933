<template>
  <div class="login ui block">
    <c-header subtitle="Connexion"></c-header>

    <div class="content text-center">
      <div :class="{'d-flex fullscreen align-items-center justify-content-center' : loader}">
        <beat-loader :color="this.$colors.blue" v-if="loader"></beat-loader>

        <div v-if="!loader">
          <img src="../assets/img/login-user.svg" class="avatar" alt="Avatar">
          <h3>{{ $t('views.login.title') }}</h3>

          <form v-on:submit.prevent="login" :class="{'animated shake': form.error }" method="POST">
            <div class="input icon" :class="{'has-error': errors.has('email') }">
              <input type="email" name="email" v-bind:placeholder="$t('views.login.email')" v-model="form.email"
                     v-validate="'required|email'" :disabled="isLockout" />
              <img src="../assets/img/icon-at.svg" alt="@">
            </div>
            <p class="text-danger" style="font-size: 12px; margin: 5px;" v-if="errors.has('email')">
              {{ errors.first('email') }}
            </p>

            <div class="input icon">
              <input type="password" name="password" autocomplete v-bind:placeholder="$t('views.login.password')"
                     v-model="form.password" v-validate="'required'" />
              <img src="../assets/img/icon-key.svg" alt="Clé">
            </div>
            <p class="text-danger" style="font-size: 12px; margin: 5px;" v-if="errors.has('password')">
              {{ errors.first('password') }}
            </p>

            <div class="input button">
              <button type="submit">
                <img src="../assets/img/ui-button-blue-validate.svg" alt="Valider">
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <c-footer color="" classes="text-center" :active="!loader">
      <router-link :to="{name:'PasswordForget'}">{{ $t('views.login.forget') }}</router-link>
    </c-footer>
  </div>
</template>

<script>
import router, {ROUTE_NAME} from "../plugins/router"

import CHeader from '../components/Header'
import CFooter from '../components/Footer'
import BeatLoader from 'vue-spinner/src/BeatLoader'
import {mapGetters} from "vuex";

export default {
  name: 'LoginView',
  data() {
    return {
      loader: false,
      form: {
        error: false,
        email: "",
        password: ""
      }
    }
  },
  computed: {
    ...mapGetters({
      'credentials': 'Auth/getCredentials',
      'isLockout': 'Auth/isLockout',
    })
  },
  methods: {
    /**
     * Make error animation
     * @private
     */
    _formError: function () {
      this.form.error = true;

      setTimeout(() => {
        this.form.error = false;
      }, 1500);
    },
    /**
     * Try login
     */
    login: function () {
      this.loader = true;

      this.$validator.validate().then(async valid => {
        if (!valid) {
          this.loader = false;
          this._formError()
        } else {
          try {
            await this.$store.dispatch('Auth/login', {
              username: this.form.email,
              password: this.form.password
            })
            await router.push({name: ROUTE_NAME.home});
          } catch (e) {
            this._formError();
          } finally {
            this.loader = false;
          }
        }
      });
    }
  },
  components: {
    "c-header": CHeader,
    "c-footer": CFooter,
    BeatLoader
  }, mounted() {
    if (this.isLockout)
      this.form.email = this.credentials.username
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content {
  img.avatar {
    margin-bottom: 25px;
  }

  h3 {
    margin-bottom: 22px;
  }

  form {
    max-width: 340px;
    margin: 0 auto;
  }

  .element {
    position: relative;
    top: -20px;
  }
}
</style>
