<template>
  <footer
      v-bind:class="[{'offline' : !isOnline},{'tosync' : (isOnline && $root.$children[0].toSyncCardSends)}, classes, color]"
      v-if="active">
    <slot></slot>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  props: {
    'classes': {
      type: String,
      default: '',
      required: false
    },
    'active': {
      type: Boolean,
      default: true,
      required: false
    },
    'color': {
      type: String,
      default: "bg-blue",
      required: false
    }
  }
}
</script>

<style scoped></style>
