import Vue from 'vue'
import Router from 'vue-router'
import Store from "../store/_index";

export const ROUTE_NAME = {
	home: 'Home',
	login: 'Login',
	password_forget: 'PasswordForget',
	menu: 'Menu',
	sync: 'Sync',
	new: 'New',
	recent: 'Recent',
	prospects: 'Prospects',
	documentations: 'Documentations',
	card: 'Card',
	card_qrcode: 'CardQrCode',
	card_send: 'CardSend',
}

/* VIEWS*/
import Home from '../views/Home'
import Login from '../views/Login'
import PasswordForget from '../views/PasswordForget'
import Menu from '../views/Menu'
import New from '../views/New'
import Sync from '../views/Sync'
import Card from '../views/Card'
import CardSend from '../views/CardSend'
import CardQrCode from '../views/CardQrCode'

Vue.use(Router);

const router = new Router({
	routes: [
		{
			path: '/',
			name: ROUTE_NAME.home,
			component: Home,
			meta: {
				noFilter: true
			}
		},
		{
			path: '/login',
			name: ROUTE_NAME.login,
			component: Login,
			meta: {
				requiresGuest: true
			}
		},
		{
			path: '/password-forget',
			name: ROUTE_NAME.password_forget,
			component: PasswordForget,
			meta: {
				requiresGuest: true
			}
		},
		{
			path: '/menu',
			name: ROUTE_NAME.menu,
			component: Menu,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/sync',
			name: ROUTE_NAME.sync,
			component: Sync,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/new',
			name: ROUTE_NAME.new,
			component: New,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/recent',
			name: ROUTE_NAME.recent,
			component: Menu,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/prospects',
			name: ROUTE_NAME.prospects,
			component: Menu,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/documentations',
			name: ROUTE_NAME.documentations,
			component: Menu,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/card',
			name: ROUTE_NAME.card,
			component: Card,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/cardQrCode',
			name: ROUTE_NAME.card_qrcode,
			component: CardQrCode,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/cardSend',
			name: ROUTE_NAME.card_send,
			component: CardSend,
			meta: {
				requiresAuth: true
			}
		}
	]
})

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
	if (requiresAuth) {
		if (!Store.getters['Auth/hasCredentials']) {
			next('/');
		} else {
			next();
		}
		return;
	}

	const requiresGuest = to.matched.some(record => record.meta.requiresGuest);
	if (requiresGuest) {
		if (Store.getters['Auth/hasCredentials']) {
			next('/menu');
		} else {
			next();
		}
		return;
	}

	const noFilter = to.matched.some(record => record.meta.noFilter);
	if (noFilter) {
		next();
	}
});

export default router;
