<template>
  <div class="carte-de-visite fullscreen ui block">
    <c-header subtitle="Ma carte de visite" button></c-header>

    <div class="content text-center" v-if="user === null">
      <beat-loader :color="this.$colors.blue" style="margin-top: 240px"></beat-loader>
    </div>

    <div class="content no-padding flex-container" v-if="user !== null">
      <div class="flex-item-center">
        <div class="pane element">
          <div class="view-recap animated fadeIn">
            <img src="../assets/img/login-user.svg" alt="">
            <h4>{{ user.firstname }} {{ user.name }}</h4>
            <h5>{{ user.status }}</h5>

            <table class="details">
              <tbody>
              <tr>
                <td><i class="fas fa-mobile-alt"></i>&nbsp;&nbsp;</td>
                <td class="text-left">{{ user.mobilephone }}</td>
              </tr>
              <tr>
                <td><i class="fas fa-phone"></i>&nbsp;&nbsp;</td>
                <td class="text-left">{{ user.phone }}</td>
              </tr>
              <tr>
                <td><i class="fas fa-fax"></i>&nbsp;&nbsp;</td>
                <td class="text-left">{{ user.fax }}</td>
              </tr>
              <tr>
                <td><i class="fas fa-envelope"></i>&nbsp;&nbsp;</td>
                <td class="text-left">{{ user.email }}</td>
              </tr>
              <tr>
                <td><i class="fas fa-globe"></i>&nbsp;&nbsp;</td>
                <td class="text-left">&nbsp;{{ user.web }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <c-footer color="" classes="text-right" :active="(user !== null)">
      <router-link :to="{name:'CardSend'}">
        <img src="../assets/img/ui-button-blue-mail.svg" alt="Envoyer par mail" style="margin-right: 5px;">
      </router-link>

      <router-link :to="{name:'CardQrCode'}">
        <img src="../assets/img/ui-button-blue-qrcode.svg" alt="Qr Code">
      </router-link>
    </c-footer>


  </div>
</template>

<script>
import Router, {ROUTE_NAME} from "../plugins/router";
import BeatLoader from 'vue-spinner/src/BeatLoader'
import CHeader from "../components/Header";
import CFooter from '../components/Footer'
import {mapGetters} from "vuex";

export default {
  name: 'CardQrCode',
  data() {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters({
      'user': 'User/getUserDetails'
    })
  },
  components: {
    "c-header": CHeader,
    "c-footer": CFooter,
    BeatLoader
  },
  mounted: function () {
    if (!this.$store.getters['User/hasUserDetails'])
      Router.push({name: ROUTE_NAME.sync});
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content {
  .element {
    position: relative;
    margin-top: -45px;
  }

  .view-recap {
    padding-bottom: 0;
    padding-top: 10px;
  }

  .qrcode span {
    display: block;
    margin-top: 10px;
    color: white;
    font-size: 14px;
  }
}

footer {
  padding-top: 0;
}

.popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px;
  background: #929da2;
  color: #ffffff;
}
</style>
