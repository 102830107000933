<template>
  <header v-bind:class="color">
    <div class="relative">
      <h1>{{ $env.APP_NAME }}</h1>
      <h2>{{ subtitle }}</h2>

      <div class="menu" v-if="button" v-on:click="menu">
        <img src="../assets/img/menu-expand.svg" alt="">
      </div>
    </div>
  </header>
</template>

<script>
import Router, {ROUTE_NAME} from "../plugins/router";

// TODO : Merge trad
export default {
  name: 'HeaderComponent',
  props: {
    'subtitle': {
      type: String,
      required: true
    },
    'button': {
      type: Boolean,
      required: false
    },
    'color': {
      type: String,
      default: "bg-blue",
      required: false
    }
  },
  methods: {
    menu: () => {
      Router.push({name: ROUTE_NAME.menu})
    }
  }
}
</script>

<style scoped lang="scss">
</style>
