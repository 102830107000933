import _ from "lodash";

_.mixin({
	"slugify": function (string) {
		const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
		const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------'
		const p = new RegExp(a.split('').join('|'), 'g')

		return string.toString().toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, '-and-') // Replace & with 'and'
			.replace(/[^\w-]+/g, '') // Remove all non-word characters
			.replace(/-+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, '') // Trim - from end of text
	},
	"underscoreCase": function (string) {
		return string.replace(/([A-Z])/g, function ($1) {
			return "_" + $1.toLowerCase();
		});
	},
	"dashCase": function (string) {
		return string.replace(/([A-Z])/g, function ($1) {
			return "-" + $1.toLowerCase();
		});
	},
	"toAccentLess": function (string) {
		return string.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
	},
	"toSpecialCharsLess": function (string) {
		return string.replace(/[^a-z0-9_]/g, "");
	},
	"startCaseFirst": function (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	},
	'startCaseWithoutTrim': function (someParam) {
		return (!_.isEmpty(someParam) ? someParam.charAt(0).toUpperCase() + someParam.slice(1) : '');
	},
	'toUpperWithoutTrim': function (someParam) {
		return (!_.isEmpty(someParam) ? someParam.toUpperCase() : '');
	},
	'titleCaseWithoutTrim': function (someParam) {
		return (!_.isEmpty(someParam) ? someParam.replace(
			/\w\S*/g,
			function (txt) {
				return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
			}
		) : '');
	},
	"toEuro": function (number) {
		return new Intl.NumberFormat('fr-FR', {
			style: 'currency',
			currency: 'EUR'
		}).format(number)
	},
	"toNumberTwoAfterDot": function (number) {
		return new Intl.NumberFormat('fr-FR').format(Math.round(number * 100) / 100)
	},
	"sleep": async function (ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
});

export default _;
