<template>
  <div class="fullscreen bg-blue ui text-white">
    <div class="flex-container fullscreen">
      <skew-loader v-show="(state===STATES.SYNC)" class="flex-item-center" :color="this.$colors.white"></skew-loader>

      <div v-show="(state===STATES.ERROR)" class="text-center simple">
        <div class="content">
          <img src="../assets/img/icon-sync-error.svg" alt="Error">
          <h1>Synchronisation</h1>
          <p>Un problème de synchronisation empêche l’échange de données. Si ce problème persiste merci de contacter
             l’administrateur réseau.</p>

          <a class="button button-round button-white button-meduim"
             v-on:click="make($router.currentRoute.params.force)">
            Rééssayer
          </a>
          <a v-if="!needAbsolutelySync" class="button button-round button-white button-meduim button-square button-cancel"
             v-on:click="$router.go(-1)">
            <img src="../assets/img/menu-close.svg" alt="X">
          </a>
        </div>
      </div>
      <div v-show="(state===STATES.NO_INTERNET)" class="text-center simple">
        <div class="content">
          <img src="../assets/img/icon-sync-no-internet.svg" alt="No Internet">
          <h1>Synchronisation</h1>
          <p>Vous ne semblez pas avoir de connexion internet pour synchroniser vos données avec le serveur.</p>

          <a class="button button-round button-white button-meduim"
             v-on:click="make($router.currentRoute.params.force)">
            Rééssayer
          </a>
          <a v-if="!needAbsolutelySync" class="button button-round button-white button-meduim button-square button-cancel"
             v-on:click="$router.go(-1)">
            <img src="../assets/img/menu-close.svg" alt="X">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Router, {ROUTE_NAME} from '../plugins/router'
import SkewLoader from 'vue-spinner/src/SkewLoader'

export default {
  name: 'SyncComponent',
  data() {
    return {
      state: null,
    }
  },
  components: {
    SkewLoader,
  },
  computed: {
    STATES() {
      return {
        SYNC: 'sync',
        ERROR: 'error',
        NO_INTERNET: 'no-internet',
      }
    },
    needAbsolutelySync() {
      return (
          !this.$store.getters['Events/hasValidEvents']
          || !this.$store.getters['User/hasValidUserDetails']
          || !this.$store.getters['Docs/hasValidDocumentsForEvents'](this.$store.getters['Events/getEventListIds']())
          || !this.$store.getters['Prospects/hasValidProspects']
          || !this.$store.getters['ProspectTypes/hasValidProspectTypeList']
          || this.$store.getters['Card/hasSendCardRequest']()
          || this.$store.getters['Info/hasInformationRequests']());
    }
  },
  methods: {
    changeStateSync: function () {
      this.state = this.STATES.SYNC;
    },
    changeStateError: function () {
      this.state = this.STATES.ERROR;
    },
    changeStateNoInternet: function () {
      this.state = this.STATES.NO_INTERNET;
    },
    make: function (force = false) {
      force = (force === undefined ? false : force)

      console.log("Sync worker run ==========");
      this.changeStateSync();

      this.$nextTick(async () => {
        await this._.sleep(300)
        if (this.isOnline) {
          try {
            await this.syncUserDetails(force)
            await Promise.all([
              this.syncCardSend(force),
              this.syncInfoSend(force),
            ]);
            await Promise.all([
              this.syncProspectsTypes(force),
              this.syncEventsList(force),
            ])
            this.syncProspects(force)
            await this.syncDocsEvents(force)

            await Router.push({name: ROUTE_NAME.menu});
            console.log("End sync worker ==========");
          } catch (error) {
            console.error(error);
            this.changeStateError();
          }
        } else {
          this.changeStateNoInternet();
        }
      })
    },
    /**
     * @returns {Promise<any>}
     */
    syncEventsList: function (force = false) {
      if (force || !this.$store.getters['Events/hasValidEvents']) {
        return this.$store.dispatch('Events/pullEvents');
      }
    },
    /**
     * @returns {Promise<any>}
     */
    syncUserDetails: function (force = false) {
      if (force || !this.$store.getters['User/hasValidUserDetails']) {
        return this.$store.dispatch("User/pullUser")
      }
    },
    /**
     * @returns {Promise<any>}
     */
    syncDocsEvents: function (force = false) {
      let events_ids = this.$store.getters['Events/getEventListIds']();

      // ignore if events_ids is empty array
      if (force || !this.$store.getters['Docs/hasValidDocumentsForEvents'](events_ids))
        return this.$store.dispatch("Docs/pullDocumentsForAllEvents", events_ids)
    },
    /**
     * Pulls prospects by event
     * */
    syncProspects: function (force = false) {
      let events_id = this.$store.getters['Events/getEventListIds']();

      // TODO : change this part to manage best prospects cache for all events
      if (force || !this.$store.getters['Prospects/hasValidProspects'])
        return this.$store.dispatch("Prospects/pullProspectsForAllEvents", events_id)
    },
    /**
     * Pulls prospect types
     * */
    syncProspectsTypes: function (force = false) {
      if (force || !this.$store.getters['ProspectTypes/hasValidProspectTypeList'])
        return this.$store.dispatch("ProspectTypes/pullProspectTypeList")
    },
    /**
     * Sends all contact cards that are in store && that we were unable to send before
     * @return {Promise<unknown>}
     */
    syncCardSend: function (force = false) {
      if (force || this.$store.getters['Card/hasSendCardRequest']())
        return this.$store.dispatch("Card/syncCardRequest")
    },
    /**
     * Sends emails with documentation to prospects that it wasn't possible to before
     * @return {Promise<unknown>}
     */
    syncInfoSend: function (force = false) {
      if (force || this.$store.getters['Info/hasInformationRequests']())
        return this.$store.dispatch("Info/syncInformationRequests")
    }
  },
  mounted: function () {
    this.make(this.$router.currentRoute.params?.force ?? false);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.button-cancel {
  margin-left: 6px;

  img {
    height: 15px;
  }
}
</style>
