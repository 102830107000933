<template>
  <div class="home fullscreen flex-container">
    <div class="flex-item-center text-center">
      <img src="../assets/img/logo-psi.svg" alt="PSI">
      <beat-loader :color="this.$colors.blue" style="margin-top: 40px"></beat-loader>
    </div>

  </div>
</template>

<script>
import Router, {ROUTE_NAME} from "../plugins/router";
import BeatLoader from 'vue-spinner/src/BeatLoader'

export default {
  name: 'HomeView',
  data() {
    return {}
  },
  components: {
    BeatLoader
  },
  mounted: function () {
    setTimeout(() => {
      if (this.$store.getters['Auth/hasCredentials'])
        Router.push({name: ROUTE_NAME.sync});
      else {
        Router.push({name: ROUTE_NAME.login})
      }
    }, 1000)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
