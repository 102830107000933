import axios from "axios";
import moment from "moment";

const state = {
	details: null,
	pull_at: null
};

const getters = {
	/**
	 * @param state
	 * @returns {null|Object}
	 */
	getUserDetails: state => (state.details),
	/**
	 * @param state
	 * @returns {moment.Moment}
	 */
	getLastPullDate: state => (moment(state.pull_at)),
	/**
	 * @param state
	 * @returns {boolean}
	 */
	hasUserDetails: (state) => (state.details != null),
	/**
	 * @param state
	 * @returns {boolean}
	 */
	hasValidUserDetails: (state) => {
		return (state.details != null && state.pull_at != null)
	}
};

const mutations = {
	/**
	 * @param state
	 * @param {Object} details
	 */
	setUserDetails: (state, details) => {
		state.details = details;
		state.pull_at = moment();
	},
	/**
	 * @param state
	 */
	clearUserDetails: (state) => {
		state.details = null;
		state.pull_at = null;
	},
};

const actions = {
	/**
	 * @param context
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	pullUser: async (context) => {
		let response = await axios.get('/user/details', {
			auth: {
				username: context.rootState.Auth.username,
				password: context.rootState.Auth.password
			}
		})

		context.commit('setUserDetails', response.data);
		return response
	},
	/**
	 * @param context
	 */
	clearUser: (context) => {
		context.commit('clearUserDetails');
	}
};

export default {namespaced: true, state, getters, mutations, actions};
