import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'

/* VARS */
import Environment from './env.js'
import Colors from './colors'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'open-sans-fontface/open-sans.css'
import 'bootstrap/dist/css/bootstrap-reboot.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'animate.css/animate.min.css'
import './assets/sass/all.scss'

/* STORE */
import store from './store/_index'

/* PLUGINS */
import router from './plugins/router'
import _ from "./plugins/lodash";
import {i18n} from './plugins/i18n'
import Bus from './plugins/bus'
import './plugins/vee-validate';
import "./plugins/axios";

/* LIBS */
import VueOnlinePlugin from 'vue-navigator-online'
import VueResizeText from 'vue-resize-text';
import moment from "moment";
import "moment/src/locale/fr";

// RUN
Vue.config.productionTip = false
Vue.prototype._ = _;
Vue.prototype.$bus = Bus;
Vue.prototype.$env = Environment;
Vue.prototype.$colors = Colors;
Vue.prototype.$moment = moment;

Vue.use(VueOnlinePlugin);
Vue.use(VueResizeText)

console.log("");
console.log("Details ==================================");
console.log(" - app name : ", Environment.APP_NAME);
console.log(" - app version : ", Environment.APP_VERSION);
console.log(" - mode : ", Environment.APP_MODE);
console.log(" - debug is enable : ", Environment.DEBUG);
console.log(" - cache timeout : ", Environment.APP_CACHE_TIMEOUT);
console.log(" - web-service url : ", Environment.BACKEND_URL);
console.log(" - web-service url api prefix for events: ", Environment.BACKEND_URL.replace(/\/$/, '') + '/' + Environment.BACKEND_URL_API_PREFIX.replace(/^\//, ''));
console.log("==========================================");
console.log("");
console.log("");

new Vue({
	router,
	store,
	i18n,
	render: function (h) {
		return h(App)
	}
}).$mount('#app')
