<template>
  <div id="app">

    <img src="./assets/img/icon-arrow-down.svg" alt="" style="display: none;" />
    <img src="./assets/img/icon-arrow-right.svg" alt="" style="display: none;" />
    <img src="./assets/img/icon-at.svg" alt="" style="display: none;" />
    <img src="./assets/img/icon-briefcase.svg" alt="" style="display: none;" />
    <img src="./assets/img/icon-key.svg" alt="" style="display: none;" />
    <img src="./assets/img/icon-list.svg" alt="" style="display: none;" />
    <img src="./assets/img/icon-phone.svg" alt="" style="display: none;" />
    <img src="./assets/img/icon-sync-error.svg" alt="" style="display: none;" />
    <img src="./assets/img/icon-sync-no-internet.svg" alt="" style="display: none;" />
    <img src="./assets/img/icon-synthese.svg" alt="" style="display: none;" />
    <img src="./assets/img/icon-university.svg" alt="" style="display: none;" />
    <img src="./assets/img/icon-user.svg" alt="" style="display: none;" />
    <img src="./assets/img/login-user.svg" alt="" style="display: none;" />
    <img src="./assets/img/logo-psi.svg" alt="" style="display: none;" />
    <img src="./assets/img/menu-close.svg" alt="" style="display: none;" />
    <img src="./assets/img/menu-expand.svg" alt="" style="display: none;" />
    <img src="./assets/img/menu-separator.svg" alt="" style="display: none;" />
    <img src="./assets/img/tunnel-end.svg" alt="" style="display: none;" />
    <img src="./assets/img/tunnel-end-fail.svg" alt="" style="display: none;" />
    <img src="./assets/img/tunnel-end-later.svg" alt="" style="display: none;" />
    <img src="./assets/img/ui-button-blue-close.svg" alt="" style="display: none;" />
    <img src="./assets/img/ui-button-blue-mail.svg" alt="" style="display: none;" />
    <img src="./assets/img/ui-button-blue-plus.svg" alt="" style="display: none;" />
    <img src="./assets/img/ui-button-blue-qrcode.svg" alt="" style="display: none;" />
    <img src="./assets/img/ui-button-blue-right.svg" alt="" style="display: none;" />
    <img src="./assets/img/ui-button-blue-see.svg" alt="" style="display: none;" />
    <img src="./assets/img/ui-button-blue-validate.svg" alt="" style="display: none;" />
    <img src="./assets/img/ui-radio-button.svg" alt="" style="display: none;" />
    <img src="./assets/img/ui-radio-button-check.svg" alt="" style="display: none;" />
    <img src="./assets/img/ui-select-blue-button.svg" alt="" style="display: none;" />

    <router-view />

    <template v-if="!isLockout">
      <router-link tag="div" :to="{name:'Sync'}" class="band-tosync click animated fadeInUp"
                   v-show="!($router.currentRoute.name === ROUTE_NAME.sync)"
                   v-if="isOnline && (hasSendCardRequest() || hasInformationRequests())">
        Données à synchroniser
      </router-link>
      <div class="band-offline no-click animated fadeInUp" v-if="!this.isOnline">
        Mode hors ligne
      </div>
    </template>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {ROUTE_NAME} from "@/plugins/router";

export default {
  name: 'App',
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      'hasSendCardRequest': 'Card/hasSendCardRequest',
      'hasInformationRequests': 'Info/hasInformationRequests',
      'isLockout': 'Auth/isLockout'
    }),
    /**
     * @returns {boolean}
     */
    ROUTE_NAME() {
      return ROUTE_NAME
    }
  },
  mounted() {
  }
};
</script>

<style scoped lang="scss">
.band-offline {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: rgba(235, 77, 75, 0.5);
  color: #fff;
  padding: 10px;
  text-align: center;
}

.band-tosync {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: rgba(235, 208, 75, 0.69);
  color: #fff;
  padding: 10px;
  text-align: center;
}
</style>
