import axios from "axios";
import moment from "moment";

const state = {
	username: null,
	password: null,
	set_at: null
};

const getters = {
	/**
	 * @param state
	 * @returns {{password: String, username: String}}
	 */
	getCredentials: state => ({
		'username': state.username,
		'password': state.password,
	}),
	/**
	 * @param state
	 * @returns {boolean}
	 */
	hasCredentials: state => ((state.username !== null && state.password !== null)),
	isLockout: state => ((state.username !== null && state.password === null && state.set_at !== null)),
	/**
	 *
	 * @param state
	 * @returns {moment}
	 */
	getCredentialsDatetime: state => (state.set_at)
};

const mutations = {
	/**
	 * @param state
	 * @param {{username: String, password: String, set_at : null|moment}} data
	 */
	setCredentials: (state, data) => {
		state.username = data.username;
		state.password = data.password;
		state.set_at = data.set_at || moment();
	}
};

const actions = {
	/**
	 * @param context
	 * @param {{username:String, password:String}} credentials
	 * @returns {Promise<void>}
	 */
	login: async (context, credentials) => {
		let response = await axios.get('/login', {
			auth: {
				username: credentials.username,
				password: credentials.password
			}
		})

		context.commit('setCredentials', credentials);
		return response
	},
	/**
	 * @param context
	 * @param {String} email
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	passwordForget: async (context, email) => {
		return await axios.post('/password-forgot', {
			email: email
		});
	},
	/**
	 * @param context
	 */
	logout: (context) => {
		context.commit('setCredentials', {
			username: null,
			password: null,
			set_at: null
		});
	},
	/**
	 * @param context
	 */
	lockout: (context) => {
		context.commit('setCredentials', {
			username: context.state.username,
			password: null,
			set_at: context.state.set_at
		});
	}
};

export default {namespaced: true, state, getters, mutations, actions};
