import axios from "axios";
import uniqid from "uniqid";

export const STATUS = {
	LATER: 'later',
	SENT: 'sent'
}

const state = {
	information_request: {}
};

const getters = {
	/**
	 * @param state
	 * @returns {Object}
	 */
	getInformationRequests: state => (state.information_request),
	/**
	 * @param state
	 * @returns {boolean}
	 */
	hasInformationRequests: state => () => {
		return (state.information_request !== undefined ? Object.keys(state.information_request).length >= 1 : false)
	}
};

const mutations = {
	/**
	 * @param state
	 * @param {{ prospect_id: null|Number, event_id: Number, docs: Array, email: String, name: String, firstname: String, organisation: String, phone: String, project: String, status: String, set_at: moment.Moment }} information
	 */
	addInformationRequest: (state, information) => {
		let id = uniqid();

		state.information_request[id] = information
		state.information_request[id].id = id;
		state.information_request[id].set_at = new Date();
	},
	/**
	 * @param state
	 * @param {Number} id
	 * @returns {boolean}
	 */
	removeInformationRequest: (state, id) => {
		delete state.information_request[id];
		return true;
	},
	/**
	 * @param state
	 * @param {Object} data
	 */
	setInformationRequests: (state, data = {}) => {
		state.information_request = data;
	}
};

const actions = {
	/**
	 * @param context
	 * @param {{ id: null|Number, prospect_id: null|Number, event_id: Number, docs: Array, email: String, name: String, firstname: String, organisation: String, phone: String, project: String, status: String }} information
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	sendInformationRequestAction: (context, information) => {
		return axios({
			method: 'post',
			url: '/event/' + information.event_id + '/sync',
			auth: {
				username: context.rootState.Auth.username,
				password: context.rootState.Auth.password
			},
			data: {
				prospect_id: information.prospect_id,
				docs: information.docs,
				email: information.email,
				name: information.name,
				firstname: information.firstname,
				organisation: information.organization,
				phone: information.phone,
				project: information.project,
				status: information.status_specific !== null ? information.status_specific : information.status
			}
		});
	},
	/**
	 * @param context
	 * @param {{ id: null|Number, prospect_id: null|Number, event_id: Number, docs: Array, email: String, name: String, firstname: String, organisation: String, phone: String, project: String, status: String, set_at: moment.Moment }} information
	 * @returns {Promise<string>}
	 */
	sendInformationRequest: async (context, information) => {
		if (!window.navigator.onLine) {
			context.commit('addInformationRequest', information);
			return STATUS.LATER
		}

		try {
			await context.dispatch("sendInformationRequestAction", information)
			return STATUS.SENT
		} catch (err) {
			context.commit('addInformationRequest', information);
			return STATUS.LATER
		}
	},
	/**
	 * @param context
	 * @returns {Promise<void>}
	 */
	syncInformationRequests: async (context) => {
		if (!context.getters.hasInformationRequests()) return;

		for (const informationRequests of Object.values(context.getters.getInformationRequests)) {
			try {
				await context.dispatch("sendInformationRequestAction", informationRequests)
				context.commit("removeInformationRequest", informationRequests.id);
			} catch (err) {
				// nothing to catch
			}
		}

		return !context.getters.hasInformationRequests()
	},
	/**
	 * @param context
	 */
	clearInformationRequests: (context) => {
		context.commit('setInformationRequests', {});
	}
};

export default {namespaced: true, state, getters, mutations, actions};
