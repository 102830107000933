import VeeValidate from 'vee-validate';
import Vue from 'vue'
import {i18n} from './i18n'
// import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from '../constants/langs'
import fr from 'vee-validate/dist/locale/fr'


// Pass options to make all validators use the arabic language, also merge the english and arabic attributes with the internal dictionary.
Vue.use(VeeValidate, {
	i18n,
	dictionary: {
		fr
	}
});
