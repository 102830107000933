import axios from "axios";
import moment from "moment";

const state = {
	data: null
};

const getters = {
	/**
	 * @param state
	 * @returns {function({eventId : Number})}
	 */
	getDocumentsForEvent: (state) => (eventId) => {
		return state.data[eventId]['docs'] || [];
	},
	/**
	 * @param state
	 * @returns {function({id : Number})}
	 */
	getDocumentsLastPullDateForEvent: (state) => (id) => {
		return state.data[id]?.pull_at || null
	},
	/**
	 * @param state
	 * @returns {function({ids : Array|Array<Number>})}
	 */
	hasDocumentsForEvents: (state) => (ids) => {
		ids.forEach((id) => {
			if (state.data[id] === undefined) return false;
		});

		return true;
	},
	/**
	 * @param state
	 * @returns {function({id : Number}): boolean}
	 */
	hasDocumentsForEvent: (state) => (id) => {
		return state.data[id] !== undefined;
	},
	/**
	 * @param state
	 * @returns {function({ids : Array|Array<Number>}): boolean}
	 */
	hasValidDocumentsForEvents: (state) => (ids) => {
		if (state.data === null || state.data === undefined)
			return false

		return ids
			.filter(function (id) {
				let event = state.data[id];

				return (
					event !== undefined
					&& event !== null
					&& event?.docs !== null
					&& event?.pull_at !== null
				)
			})
			.length >= 1;
	},
	/**
	 * @param state
	 * @returns {function({id : Number}): boolean}
	 */
	hasValidDocumentsForEvent: (state) => (id) => {
		if (state.data === null || state.data === undefined)
			return false

		let event = state.data[id];
		return (
			event !== undefined
			&& event !== null
			&& event?.docs !== null
			&& event?.pull_at !== null
		)
	},
};

const mutations = {
	/**
	 * @param state
	 * @param {Number} event_id
	 * @param {Object|Array<Object>} docs
	 */
	setDocumentsForEvent: (state, {event_id, docs}) => {
		if (state.data == null) state.data = {};

		state.data[event_id] = {
			docs: docs,
			pull_at: moment()
		};
	},
	/**
	 * @param state
	 * @param {Object} data
	 */
	setDocumentsForAllEvents: (state, data) => {
		state.data = data;
	}
};

const actions = {
	/**
	 * @param context
	 * @param {Number} id
	 * @returns {Promise<void>}
	 */
	pullDocumentsForEvent: async (context, id) => {
		let response = await (axios.get('/event/' + id + '/documentations/list', {
			auth: {
				username: context.rootState.Auth.username,
				password: context.rootState.Auth.password
			}
		}));

		context.commit('setDocumentsForEvent', {
			event_id: id,
			docs: response?.data
		});
	},
	/**
	 * @param context
	 * @param {Array<Number>} ids
	 * @returns {Promise<void>}
	 */
	pullDocumentsForAllEvents: async (context, ids) => {
		await Promise.all(ids.map((id) => {
			return context.dispatch('pullDocumentsForEvent', id);
		}))
	},
	/**
	 * @param context
	 */
	clearDocumentsForAllEvents: (context) => {
		context.commit('setDocumentsForAllEvents', {});
	}
};

export default {namespaced: true, state, getters, mutations, actions};
