const common = {
	APP_NAME: "PSI | Events",
	APP_COPYRIGHT: "PSI",
	APP_VERSION: "1.0",
	APP_MODE: process.env.NODE_ENV,
};

const prod = {
	BACKEND_URL: "https://events.psi.fr/",
	BACKEND_URL_API_PREFIX: "/api",
	DEBUG: false,
};

const dev = {
	BACKEND_URL: "http://127.0.0.1:8000/",
	BACKEND_URL_API_PREFIX: "/api",
	DEBUG: true,
};

export default (process.env.NODE_ENV === "production" ? Object.assign(common, prod) : Object.assign(common, dev))
