import axios from "axios";
import moment from "moment"

const state = {
	selected_event_id: null,
	events: null,
	pull_at: null
};

const getters = {
	/**
	 * @param state
	 * @returns {null|Object}
	 */
	getEventList: state => (state.events),
	/**
	 * @param state
	 * @returns {function(): Array<Number>}
	 */
	getEventListIds: state => () => {
		return (state.events?.map(event => event.id) || null)
	},
	/**
	 * @param state
	 * @returns {moment.Moment}
	 */
	getLastPullDate: state => (moment(state.pull_at)),
	/**
	 * @param state
	 * @returns {Number|null}
	 */
	getSelectedEventId: state => (state.selected_event_id),
	/**
	 * @param state
	 * @returns {Object|null}
	 */
	getSelectedEvent: state => {
		return state.events?.filter(event => event.id === state.selected_event_id)[0] || null
	},
	/**
	 * @param state
	 * @returns {boolean}
	 */
	hasEvents: (state) => (state.events != null && state.events.length >= 1),
	/**
	 * @param state
	 * @returns {boolean}
	 */
	hasValidEvents: (state) => {
		return (state.events != null && state.pull_at != null)
	}
};

const mutations = {
	/**
	 * @param state
	 * @param {Number|null} event_id
	 */
	selectEvent: (state, event_id) => {
		state.selected_event_id = event_id;
	},
	/**
	 * @param state
	 * @param {Array} data
	 */
	setEvents: (state, data) => {
		state.events = data;
		state.pull_at = new Date();
	},
	/**
	 * @param state
	 */
	clearEvents: (state) => {
		state.events = null;
		state.pull_at = null;
	}
};

const actions = {
	/**
	 * @param context
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	pullEvents: async (context) => {
		let response = await axios.get('/events/list', {
			auth: {
				username: context.rootState.Auth.username,
				password: context.rootState.Auth.password
			}
		})

		context.commit('setEvents', response.data);
		return response
	},
	/**
	 * @param context
	 */
	clearEvents: (context) => {
		context.commit('clearEvents');
	}
};

export default {namespaced: true, state, getters, mutations, actions};
