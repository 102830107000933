import axios from "axios";
import moment from "moment";

const state = {
	data: null,
	pull_at: null
};

const getters = {
	/**
	 * @param state
	 * @returns {Object[]}
	 */
	getAllProspects: (state) => {
		if (state.data === null) return []

		let a = {}
		Object.values(state.data)
			.map(e => (typeof e === "object" ? Object.values(e) : e))
			.flat()
			.forEach((prospect) => {
				a[prospect.id] = prospect
			})

		return Object.values(a)
	},
	/**
	 * @param state
	 * @returns {(function(eventId:Number): Object[])}
	 */
	getProspectsOfEvent: (state) => (eventId) => {
		if (state.data === null) return []
		return state.data[eventId] || []
	},
	/**
	 * @param state
	 * @returns {(function(email:String): Object)}
	 */
	findProspectByEmail: (state) => (email) => {
		if (state.data === null) return null

		let a = {}
		Object.values(state.data)
			.map(e => (typeof e === "object" ? Object.values(e) : e))
			.flat()
			.forEach((prospect) => {
				a[prospect.id] = prospect
			})

		let search = Object.values(a).filter(prospect => (prospect.email !== undefined && prospect.email.toLowerCase().trim() === email.toLowerCase().trim()))
		if (search.length >= 1)
			return search[0];

		return null;
	},
	/**
	 * @param state
	 * @returns {moment.Moment|null}
	 */
	getLastPullDate: (state) => {
		if (state.pull_at == null) return null;
		return moment(state.pull_at);
	},
	/**
	 * @param state
	 * @returns {function(): boolean}
	 */
	hasProspects: (state) => () => {
		return (state.data != null);
	},
	/**
	 * @param state
	 * @returns {boolean}
	 */
	hasValidProspects: (state) => (state.data != null && state.pull_at != null)
};

const mutations = {
	/**
	 * @param state
	 * @param event
	 * @param {Array<Object>} prospects
	 */
	setProspects: (state, {event, prospects}) => {
		if (state.data === null) state.data = {};

		state.data[event] = prospects
		state.pull_at = moment();
	},
	/**
	 * @param state
	 */
	clearProspectsList: (state) => {
		state.data = null;
		state.pull_at = null;
	}
};

const actions = {
	/**
	 * @param context
	 * @param {Number} id
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	pullProspectsForEvent: async (context, id) => {
		let response = await axios.get('/event/' + id + '/prospects/list', {
			auth: {
				username: context.rootState.Auth.username,
				password: context.rootState.Auth.password
			}
		})

		context.commit('setProspects', {
			prospects: response.data,
			event: id
		})

		return response
	},
	/**
	 * @param context
	 * @param {Array<Number>} ids
	 * @returns {Promise<Awaited<AxiosResponse<any>>>}
	 */
	pullProspectsForAllEvents: (context, ids) => {
		return Promise.all(ids.map(function (id) {
			return context.dispatch('pullProspectsForEvent', id);
		}))
	},
	/**
	 * @param context
	 */
	clearAllProspects: (context) => {
		context.commit('clearProspectsList');
	}
};

export default {namespaced: true, state, getters, mutations, actions};
