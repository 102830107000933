<template>
  <div class="carte-de-visite fullscreen ui block">
    <c-header subtitle="Ma carte de visite" button></c-header>

    <div class="content no-padding bg-blue text-center d-flex align-items-center justify-content-center">
      <div class="flex-item-center qrcode animated fadeIn" v-if="user != null">
        <qrcode :value="urlQrCode"
                :options="{ size: 200, background:this.$colors.blue, foreground:this.$colors.white }"></qrcode>
        <span>{{ urlQrCode }}</span>
      </div>
      <div v-else class="element">
        <beat-loader :color="this.$colors.white" style="margin-top: 40px"></beat-loader>
      </div>
    </div>
    <c-footer classes="d-flex align-items-center justify-content-center">
      <button @click="$router.back()" class="close-button">Fermer</button>
    </c-footer>
  </div>
</template>

<script>
import Router, {ROUTE_NAME} from "../plugins/router";
import CHeader from "../components/Header";
import CFooter from "../components/Footer";
import BeatLoader from 'vue-spinner/src/BeatLoader'
import VueQrcode from '@xkeshi/vue-qrcode';

export default {
  name: 'CardView',
  data() {
    return {
      user: null,
    }
  },
  methods: {},
  components: {
    "c-header": CHeader,
    "c-footer": CFooter,
    BeatLoader,
    "qrcode": VueQrcode
  },
  computed: {
    urlQrCode: function () {
      return this.$env.BACKEND_URL + this.user.urlkey;
    }
  },
  mounted: function () {
    if (!this.$store.getters['User/hasUserDetails']) Router.push({name: ROUTE_NAME.sync});
    else setTimeout(() => {
      this.user = this.$store.getters['User/getUserDetails'];
    }, 500)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content {
  .element {
    position: relative;
    margin-top: -65px;
  }

  .view-recap {
    padding-bottom: 0;
    padding-top: 10px;
  }

  .qrcode {
    span {
      display: block;
      margin-top: 10px;
      color: white;
      font-size: 14px;
    }
  }
}

footer {
  padding-top: 0;

  button.close-button {
    align-self: center;
    padding: 10px 18px;
    border-radius: 23px;
    background-color: rgba(176, 190, 197, 0.16);
    display: inline-block;
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    margin: 3px;
  }
}

.popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px;
  background: #929da2;
  color: #ffffff;
}
</style>
