import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate'

/* STORE MODULES */
import App from './app';
import Auth from './auth';
import User from './user';
import Events from './events';
import Card from './card';
import Docs from './docs';
import Prospects from './prospects';
import ProspectTypes from './prospect_types';
import Info from './info'

Vue.use(Vuex);

const _index = new Vuex.Store({
	modules: {App, Auth, User, Events, Card, Docs, Prospects, ProspectTypes, Info},
	plugins: [createPersistedState()]
});

export default _index;
