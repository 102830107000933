import Environment from '../env';
import Axios from 'axios';
import Router, {ROUTE_NAME} from './router';

// SETTINGS
Axios.defaults.baseURL = Environment.BACKEND_URL.replace(/\/$/, '') + '/' + Environment.BACKEND_URL_API_PREFIX.replace(/^\//, '');
Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';


// ERROR HANDLER
/**
 * Gère le reporting des erreurs
 *
 * @param {String} model
 * @param {String} method
 * @param {Error} error
 * @returns {number}
 * @constructor
 */
export const AxiosErrorHandler = (model, method, error) => {
	let message = "Error on API call '" + method + "' for '" + model + "' model.";
	let status = 0;

	if (error.response) {
		/*
		 * The request was made and the server responded with a
		 * status code that falls out of the range of 2xx
		 */
		if (Environment.DEBUG)
			console.log(message, error.response);

		status = error.response.status;
	} else if (error.request) {
		/*
		 * The request was made but no response was received, `error.request`
		 * is an instance of XMLHttpRequest in the browser and an instance
		 * of http.ClientRequest in Node.js
		 */
		if (Environment.DEBUG)
			console.log(message, error.request);

		status = -1;
	} else {
		// Something happened in setting up the request and triggered an Error
		if (Environment.DEBUG)
			console.log(message, error.message);
	}

	status = parseInt(status);

	if (status === 401) {
		this.$store.dispatch('Auth/lockout');
		// this.$store.dispatch('Events/clearEvents');

		Router.push({name: ROUTE_NAME.home});
	}

	return status;
};

export default Axios;
